import React from "react";

import { useStaticQuery, graphql } from "gatsby";

import { FormattedMessage } from "gatsby-plugin-intl";
import ContactForm from "./contactform";

import { IoIosCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";

const Contact = ({ data }) => {
  const site = data.site.siteMetadata;

  return (
    <div id='contact'>
      <div className='container'>
        <h4>
          <FormattedMessage id='contact_title' />
        </h4>
        <div className='inner'>
          <div className='contact-content'>
            <p>
              <FormattedMessage id='contact_i' />
            </p>
            <span className='block'>
              {site.phone_name}
              <FormattedMessage id='ceo' />
              <br />
              <a className='phone-link' href={`tel:${site.callable}`}>
                <IoIosCall /> {site.phone}
              </a>
            </span>
            <span className='block'>
              <a className='phone-link' href={`mailto:${site.mail}`}>
                <MdEmail /> {site.mail}
              </a>
            </span>
          </div>
          <hr />
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

const contactQuery = graphql`
  query {
    site: site {
      siteMetadata {
        title
        keywords
        slogen
        social {
          twitter
        }
        phone
        phone_name
        callable
        mail
      }
    }
  }
`;


const ContactContainer = (props) => {
  const data = useStaticQuery(contactQuery);
  return <Contact {...props} data={data} />;
};

export default ContactContainer;